(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/common/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/sportinfo/common/assets/javascripts/helpers.js');
"use strict";


const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const {
  productIds
} = svs.isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const sportInfoConstants = svs.isServer ? require('./constants.es6') : svs.components.sportinfo.common.constants;
const {
  AllKnownOutcomes,
  EventTypeId,
  EventTypeDistributionsFallback,
  EventTypeField,
  ParticipantTypes,
  TypeForEventTypeField,
  OutcomeToAlphabeticStrings,
  OutcomeToAlphabeticStringsOverUnder
} = sportInfoConstants;
const createBetEventKey = (productId, drawId, eventNumber) => "".concat(productId, "_").concat(drawId, "_").concat(eventNumber);

const createEventStatisticsKey = _ref => {
  let {
    matchId,
    eventTypeId = EventTypeId.EVENT_1X2,
    periodNumber
  } = _ref;
  let eventStatisticsKey = "".concat(matchId, "_").concat(eventTypeId);
  if (typeof periodNumber === 'number' && periodNumber > 0) {
    eventStatisticsKey += "_P".concat(periodNumber);
  }
  return eventStatisticsKey;
};
const capitalize = str => {
  if (!str) {
    return str;
  }
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};
const normalizeToDot = str => str ? "".concat(str).replace(/,/g, '.') : null;
const getValueForField = (values, fieldName) => values ? values[fieldName] : undefined;
const getValueForPreviousField = (values, outcome) => getValueForField(values, "ref".concat(capitalize(outcome)));
const normalizeValueField = function (eventTypeId, values, fn) {
  let allKnownOutcomes = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : AllKnownOutcomes;
  const outcomes = allKnownOutcomes[eventTypeId] || ['one', 'x', 'two'];
  return outcomes.map(knownOutcome => {
    const field = fn(values, knownOutcome);
    return normalizeToDot(field);
  });
};
const normalizeEventTypeId = _ref2 => {
  let {
    productId,
    participantType = 'Team',
    eventTypeId
  } = _ref2;
  const isEventTypeIdH2H = productIds.POWERPLAY === productId && participantType === ParticipantTypes.Player;
  if (isEventTypeIdH2H) {
    return EventTypeId.HEAD_TO_HEAD;
  }
  if ([productIds.BOMBEN, productIds.MATCHEN].includes(productId)) {
    return EventTypeId.RESULT;
  }
  return eventTypeId;
};
const normalizeEventTypeField = function (eventTypeId, eventTypeField, values) {
  let subkey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : EventTypeDistributionsFallback;
  const eventTypeFields = {};
  eventTypeFields[eventTypeField] = {};
  if (values) {
    if (eventTypeField === EventTypeField.Distributions) {
      const productIds = Object.keys(values);
      productIds.forEach(productId => {
        const value = values[productId];
        if (!eventTypeFields[eventTypeField][productId]) {
          eventTypeFields[eventTypeField][productId] = {};
        }
        const parsedDistribution = {
          current: {
            value: normalizeValueField(eventTypeId, value, getValueForField, AllKnownOutcomes),
            date: getValueForField(value, 'date')
          },
          previous: {
            value: normalizeValueField(eventTypeId, value, getValueForPreviousField, AllKnownOutcomes),
            date: getValueForPreviousField(value, 'date')
          },
          valueType: TypeForEventTypeField[eventTypeField]
        };
        eventTypeFields[eventTypeField][productId][subkey] = parsedDistribution;
      });
    } else {
      eventTypeFields[eventTypeField] = {
        current: {
          value: normalizeValueField(eventTypeId, values, getValueForField, AllKnownOutcomes)
        },
        valueType: TypeForEventTypeField[eventTypeField]
      };
    }
  }
  return eventTypeFields;
};
const normalizeOddsItem = (odds, eventTypeId) => {
  if (odds.length === 0) {
    return null;
  }
  const getOutcomeKey = outcome => {
    if (eventTypeId === EventTypeId.EVENT_1X2) {
      return OutcomeToAlphabeticStrings[outcome];
    }
    if (eventTypeId === EventTypeId.OVER_UNDER) {
      return OutcomeToAlphabeticStringsOverUnder[outcome];
    }
    return outcome;
  };
  return odds.reduce((acc, outcome) => {
    acc[getOutcomeKey(outcome.outcome)] = outcome.value;
    return acc;
  }, {});
};
const helperExports = {
  createBetEventKey,
  createEventStatisticsKey,
  normalizeEventTypeField,
  normalizeEventTypeId,
  normalizeOddsItem,
  normalizeToDot
};
if (svs.isServer) {
  module.exports = helperExports;
} else {
  setGlobal('svs.components.sportinfo.common.helpers', helperExports);
}

 })(window);